import React from 'react'
import { Link } from 'gatsby'
import { MDXProvider } from "@mdx-js/react"

import { rhythm, scale } from '../utils/typography'
import { CosinusTransform } from './dct'
import { ModuloTable } from './math'
import { RotatingCube } from './rotation'


function Separator(props) {
  return <div style={{marginTop: "-0.75em", marginBottom: "1em", textAlign: "center", fontWeight: "bold", fontSize: "16px"}}>{props.text || "∗ ∗ ∗"}</div>
}

// See https://www.gatsbyjs.org/docs/mdx/importing-and-using-components/
const shortcodes = { Separator, CosinusTransform, ModuloTable, RotatingCube }

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(26),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <div>{header}</div>
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
        <footer>
          <small>
            © {new Date().getFullYear()} <Link to="/">Lipsum.dev</Link> — <Link to="/about">À propos</Link>
          </small>
        </footer>
      </div>
    )
  }
}

export default Layout
