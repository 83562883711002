import React from 'react'
import * as range from 'lodash/range'

export const ModuloTable = (props) => {
    const compute = (props.op == '+') ? (x, y) => (x + y) : (x, y) => (x * y)
    return (
    <table style={{width: "60%", marginLeft: "20%"}}>
        <thead>
            <tr>
                <th>{props.op}</th>
                {range(props.n).map((i) => <th key={i}>{i}</th>)}
            </tr>
        </thead>
        <tbody>
            {range(props.n).map((i) => 
            <tr key={i}>
                <th>{i}</th>
                {range(props.n).map((j) => <td key={`${i}_${j}`}>{compute(i, j) % props.n}</td>)}
            </tr>
            )}
        </tbody>
    </table>)
}
